@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Semibold";
  src: url("./assets/fonts/RobotoSerif-SemiBold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Abel";
  src: url("./assets/fonts/Abel-Regular.ttf");
}

.blue-bg-gradient {
  background: linear-gradient(109.19deg, #243677 -7.1%, #011048 63.98%);
}
.bg_report {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(./assets/images/background/report_bg.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 78vh;
}
.bullet-point {
  list-style-type: disc;
  padding-left: 1.5rem;
}
.bullet-point2 {
  list-style-type: decimal;
  padding-left: 1.5rem;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(even) {
  background-color: #dddddd;
} */
tspan {
  font-family: 'Abel' !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  padding: 0 !important;
  text-transform: capitalize;
}
:where(.css-dev-only-do-not-override-mxhywb).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #243677 !important;
}

@media only screen and (min-width: 240px) and (max-width: 767px) {
/* sidebar float on mobile  */
:where(.css-dev-only-do-not-override-mxhywb).ant-layout .ant-layout-sider {
  position: absolute;
  z-index: 10;
}
}